import ContentTable from '@pretto/bricks/website/shared/components/ContentTable'

import PropTypes from 'prop-types'

import * as S from '../styles'

const Table = ({ content, footerRows, headerColumns, headerRows, hideTitle, ...props }) => (
  <S.BlockLarge>
    <ContentTable
      footerRows={parseInt(footerRows || 0, 10)}
      headerColumns={parseInt(headerColumns || 0, 10)}
      headerRows={parseInt(headerRows || 0, 10)}
      hideTitle={!!hideTitle}
      {...props}
    >
      {content}
    </ContentTable>
  </S.BlockLarge>
)

Table.propTypes = {
  content: PropTypes.node.isRequired,
  footerRows: PropTypes.string,
  headerColumns: PropTypes.string,
  headerRows: PropTypes.string,
  hideTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Table
